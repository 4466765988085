import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import loader from '../assets/loader.svg';
import mobileLoader from '../assets/mobile-loader.svg';

const Loader = () => {
  const [showLoader, setShowLoader] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  // Detect if the device is mobile
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Check on component mount and when resizing
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, 3000); // Hide loader after 3 seconds

    return () => clearTimeout(timer);
  }, []);

  if (!showLoader) {
    return null;
  }

  return isMobile ? (
    <FaviconContainer>
      <Favicon src={mobileLoader} alt="Loading..." />
    </FaviconContainer>
  ) : (
    <LoaderContainer>
      <CenteredImage src={loader} alt="Loading..." />
    </LoaderContainer>
  );
};

// Styled-component for the loader container
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
`;

// Styled-component for the image (no rotation)
const CenteredImage = styled.img`
  width: 100vw;
  height: 100vh;
  object-fit: cover;
`;

// Favicon container for mobile
const FaviconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
`;

// Favicon style
const Favicon = styled.img`
  width: 90px;
  height: 90px;
`;

export default Loader;