const coursesData = [
  {
    id: 1,
    title: 'C++',
    info: 'Start with our beginner-friendly course, where you’ll learn the basics of programming.',
    price: '700',
    curriculum: `
      - **Introduction to C++**
      - **Object-Oriented Programming**
      - **Data Structures**
      - **Algorithms**`
  },
  {
    id: 2,
    title: 'Python',
    info: 'Learn Python programming from scratch and build your first Python application.',
    price: '700',
    curriculum: `
      - **Basics of Python Programming**
      - **Object-Oriented Programming in Python**
      - **Data Structures in Python**
      - **Algorithmic Thinking**`
  },
  {
    id: 3,
    title: 'Intro to Cybersecurity',
    info: 'An introduction to network security, encryption, and threat detection.',
    price: '900',
    curriculum: `
      - **Introduction to Cybersecurity**
      - **Cryptography Fundamentals**
      - **Linux Basics**
      - **Networking Essentials**`
  },
  {
    id: 4,
    title: 'Web Development',
    info: 'Learn HTML, CSS, JavaScript, and responsive design with frameworks like React.',
    price: '900',
    curriculum: `
      - **HTML Fundamentals**
      - **CSS & Responsive Design**
      - **JavaScript Basics**
      - **Frontend Frameworks: Bootstrap & React**`
  }
];

export default coursesData;
