import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const CourseCard = ({ id, title, info, originalPrice, discountedPrice }) => {
  const navigate = useNavigate();

  // Handler for navigating to the course details page
  const handleCardClick = () => {
    navigate(`/courses/${id}`);
  };

  return (
    <CardContainer onClick={handleCardClick}>
      <CardContent>
        <CardHeader>{title}</CardHeader>
        <CardDetails>{info}</CardDetails>
        <CardPrices>
          {discountedPrice ? (
            <>
              <PriceLabel>Original Price:</PriceLabel>
              <OriginalPrice>{originalPrice}</OriginalPrice>
              <PriceLabel>Discounted Price:</PriceLabel>
              <DiscountedPrice>{discountedPrice}</DiscountedPrice>
            </>
          ) : (
            <>
              <PriceLabel>Price:</PriceLabel>
              <RegularPrice>{originalPrice}</RegularPrice>
            </>
          )}
        </CardPrices>
      </CardContent>
    </CardContainer>
  );
};

// Styled-components for the card
const CardContainer = styled.div`
  background: #1A73E8;
  color: #F5F5F5;
  padding: 20px;
  border-radius: 12px;
  width: 100%;
  max-width: 320px;
  text-align: left;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.8s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-8px);
    background: linear-gradient(135deg, #FF4500, #1A73E8);
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25);
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardHeader = styled.h3`
  font-size: 1.8rem;
  margin-bottom: 12px;
`;

const CardDetails = styled.p`
  font-size: 1.2rem;
  margin-bottom: 12px;
`;

const CardPrices = styled.div`
  display: flex;
  flex-direction: column;
`;

const PriceLabel = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
  color: #F5F5F5;
`;

const OriginalPrice = styled.span`
  font-size: 1.2rem;
  text-decoration: line-through;
  color: #ff4500;
  margin-bottom: 8px;
`;

const DiscountedPrice = styled.span`
  font-size: 1.5rem;
  color: #28a745;
  font-weight: bold;
  margin-bottom: 8px;
`;

const RegularPrice = styled.span`
  font-size: 1.5rem;
  color: #fff;
  font-weight: bold;
`;

export default CourseCard;
