import React, { useState } from 'react';
import styled from 'styled-components';

const Contact = () => {
    const [messageStatus, setMessageStatus] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = e.target;
        const formData = new FormData(form);

        // Replace YOUR_FORMSPREE_ENDPOINT with the actual Formspree endpoint URL
        const response = await fetch('https://formspree.io/f/xdkondyb', {
            method: 'POST',
            body: formData,
            headers: {
                Accept: 'application/json',
            },
        });

        if (response.ok) {
            setMessageStatus('Message sent successfully!');
            form.reset();
        } else {
            setMessageStatus('Failed to send message. Please try again.');
        }
    };

    return (
        <ContactContainer>
            <h2>Contact Us</h2>

            <form onSubmit={handleSubmit}>
                <FieldContainer>
                    <label htmlFor="name">Name</label>
                    <input type="text" name="name" required />
                </FieldContainer>

                <FieldContainer>
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" required />
                </FieldContainer>

                <FieldContainer>
                    <label htmlFor="message">Message</label>
                    <textarea name="message" rows="5" required></textarea>
                </FieldContainer>

                <Button type="submit">Send Message</Button>

                {messageStatus && <StatusMessage>{messageStatus}</StatusMessage>}
            </form>
        </ContactContainer>
    );
};

// Styled-components for Contact Us
const ContactContainer = styled.div`
  padding: 40px;
  background: rgba(26, 115, 232, 1); /* Consistent blue background */
  color: #f5f5f5;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
  text-align: left;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
`;

const FieldContainer = styled.div`
  margin-bottom: 20px;
  margin-right: 20px;

  label {
    display: block;
    margin-bottom: 5px;
    margin-right: 20px;
    font-size: 1.2rem;
    color: #f5f5f5;
  }

  input,
  textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    background-color: #f5f5f5;
    color: #2c2e33;
  }

  textarea {
    resize: none;
  }
`;

const Button = styled.button`
  background: #FF4500 ; /* Gradient consistent with other components */
  color: #f5f5f5;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 100%;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  }
`;

const StatusMessage = styled.p`
  margin-top: 20px;
  color: ${(props) => (props.error ? '#ff4500' : '#00FF00')}; /* Red for errors, green for success */
`;

export default Contact;
