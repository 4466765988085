import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FaBars, FaTimes } from 'react-icons/fa'; // Import icons
import logo from '../assets/logo.png'; // Assuming logo is located here

// Reusable Navbar component
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrollDirection, setScrollDirection] = useState("up");

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Detect scroll direction to hide/show navbar
  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const handleScroll = () => {
      const currentScrollY = window.pageYOffset;
      if (currentScrollY > lastScrollY) {
        setScrollDirection("down"); // Hide navbar on scroll down
      } else {
        setScrollDirection("up"); // Show navbar on scroll up
      }
      lastScrollY = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <NavBarContainer scrollDirection={scrollDirection}>
        {/* Logo on the left that links to home */}
        <LogoLink to="/">
          <Logo src={logo} alt="U-Code Logo" />
        </LogoLink>

        <NavList>
          <NavItem>
            <StyledLink to="/">Home</StyledLink>
          </NavItem>
          <NavItem>
            <StyledLink to="/courses">Courses</StyledLink>
          </NavItem>

          {/* '                    <NavItem>
                        <StyledLink to="/about">About Us</StyledLink>
                    </NavItem> */}
          <NavItem>
            <StyledLink to="/contact">Contact Us</StyledLink>
          </NavItem>
        </NavList>

        {/* Hamburger Menu for Mobile */}
        <HamburgerIcon isOpen={isOpen} onClick={toggleMenu}>
          {isOpen ? <FaTimes style={{ color: '#FF4500' }} /> : <FaBars style={{ color: '#1A73E8' }} />}
        </HamburgerIcon>
      </NavBarContainer>

      {/* Mobile Menu Dropdown */}
      {isOpen && (
        <MobileMenu>
          <NavItem>
            <StyledLink to="/" onClick={toggleMenu}>Home</StyledLink>
          </NavItem>
          <NavItem>
            <StyledLink to="/courses" onClick={toggleMenu}>Courses</StyledLink>
          </NavItem>
          {/* <NavItem>
            <StyledLink to="/about" onClick={toggleMenu}>About Us</StyledLink>
          </NavItem> */}

          <NavItem>
            <StyledLink to="/contact" onClick={toggleMenu}>Contact Us</StyledLink>
          </NavItem>
        </MobileMenu>
      )}
    </>
  );
};

// Styled-components for the navbar and scroll behavior
const NavBarContainer = styled.nav`
    background-color: #FFFEFC; /* Off-white background */
    position: fixed;
    width: 100%;
    top: ${({ scrollDirection }) => (scrollDirection === "down" ? "-80px" : "0")}; /* Hide on scroll down */
    left: 0;
    padding: 5px;
    z-index: 1000;
    backdrop-filter: blur(5px); /* Optional: Adds a blur effect */
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: top 0.3s ease-in-out, background-color 0.3s ease-in-out; /* Smooth transition for hiding/showing */

    @media (max-width: 768px) {
        padding: 10px 20px;
    }

    /* Move the right-side items to the left a little bit */
    & > *:last-child {
        margin-right: 40px;
    }
`;

// Styled-components for the logo
const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: auto;
  height: 40px;
  margin-right: 5px;
`;

const NavList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: center;
  gap: 30px;

  @media (max-width: 768px) {
    display: none; /* Hide the full navbar on mobile */
  }
`;

const NavItem = styled.li`
  margin: 0 15px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #1A73E8; /* Blue text color */
  font-size: 1.2rem;
  transition: color 0.3s ease;

  &:hover {
    color: #FF4500; /* Bright Orange on hover */
  }
`;

// Hamburger icon for mobile
const HamburgerIcon = styled.div`
  display: none;
  font-size: 1.8rem;
  cursor: pointer;
  z-index: 1001;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(90deg)' : 'rotate(0deg)')}; /* Rotate 90 degrees when open */
  transition: transform 0.3s ease; /* Smooth rotation */

  @media (max-width: 768px) {
    display: block;
  }
`;

// Mobile dropdown menu
const MobileMenu = styled.ul`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: auto;
  background-color: rgba(26, 115, 232, 1); /* Semi-transparent blue */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
  border-radius: 10px;
  list-style-type: none; /* Remove bullet points */

  li {
    margin: 20px 0;
  }

  a {
    font-size: 2rem;
    color: #f5f5f5;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: #ff4500;
    }
  }
`;

export default Navbar;
