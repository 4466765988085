import React from 'react';
import styled from 'styled-components';

const Home = () => {
  return (
    <HomePageContainer>
      {/* Hero Section */}
      <HeroSection>
        <HeroHeading>
          U<span style={{ color: '#ff4500' }}>-</span>Code
          <span style={{ color: '#ff4500' }}>;</span> We Fix
        </HeroHeading>
        <HeroSubtext>
          Build your skills, create your portfolio, and shape your future with U-Code's expert-led coding courses.
        </HeroSubtext>
        <HeroButton onClick={() => window.location.href = '/courses'}>
          Explore Our Courses
        </HeroButton>
      </HeroSection>

      {/* About Section */}
      <Section>
        <SectionTitle>About U-Code Academy</SectionTitle>
        <SectionContent>
          At <strong>U-Code Academy</strong>, we are passionate about helping the next generation of innovators by offering high-quality courses in software engineering. We believe in empowering students with the skills needed to thrive in the digital world.
        </SectionContent>
      </Section>

      {/* Mission Section */}
      <Section>
        <SectionTitle>Our Mission</SectionTitle>
        <SectionContent>
          Our mission is to equip students with the essential knowledge, practical skills, and hands-on experience they need to succeed in today’s fast-paced technology landscape. We aim to bridge the gap between theory and practice, ensuring our students are ready for real-world challenges.
        </SectionContent>
      </Section>

      {/* Courses Section */}
      <Section>
        <SectionTitle>Our Courses</SectionTitle>
        <SectionContent>
          We offer a variety of programs tailored to meet the needs of learners at all levels. Whether you're a beginner starting your coding journey or an advanced student looking to deepen your expertise in software development, we have courses that cover it all.
        </SectionContent>
      </Section>

      {/* Focus Section */}
      <Section>
        <SectionTitle>Our Focus</SectionTitle>
        <SectionContent>
          At U-Code, we focus on problem-solving, creativity, and real-world application. Our courses are designed to not only teach technical skills but also foster critical thinking and innovation, ensuring students are well-prepared to lead in the evolving tech industry.
        </SectionContent>
      </Section>
    </HomePageContainer>
  );
};

// Styled-components for the Home Page
const HomePageContainer = styled.div`
  padding: 60px 20px;
  background-color: #f5f5f5;
  color: #2c2e33;
  text-align: center;
  @media (max-width: 768px) {
    padding: 40px 10px;
  }
`;

const HeroSection = styled.section`
  background: linear-gradient(135deg, #1a73e8, #ff4500);
  background-size: 300% 300%;
  animation: gradientAnimation 6s ease infinite;
  padding: 100px 20px;
  color: #fff;
  border-radius: 15px;
  text-align: center;
  margin-bottom: 50px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    padding: 60px 15px;
  }

  @keyframes gradientAnimation {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
`;

const HeroHeading = styled.h1`
  font-size: 3rem;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 2.2rem;
  }
`;

const HeroSubtext = styled.p`
  font-size: 1.4rem;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }
`;

const HeroButton = styled.button`
  background: #fff;
  color: #ff4500;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background: #ff4500;
    color: #fff;
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 1rem;
  }
`;
const Section = styled.section`
  background: #1A73E8 ;
  padding: 40px 20px;
  border-radius: 15px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;

  @media (max-width: 768px) {
    padding: 30px 15px;
  }
`;

const SectionTitle = styled.h2`
    font-size: 2.5rem;
    color: #f5f5f5;
    margin-bottom: 20px;
    @media (max-width: 768px) {
        font-size: 2rem;
    }
`;

const SectionContent = styled.p`
    font-size: 1.3rem;
    color: #f5f5f5;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
    text-align: left;
    @media (max-width: 768px) {
        font-size: 1.1rem;
    }
`;

export default Home;
