import React, { useState } from 'react';
import styled from 'styled-components';
import coursesData from '../coursesData'; // Import course data
import CourseCard from '../components/CourseCard'; // Import CourseCard component

const Courses = () => {
  const [promoCode, setPromoCode] = useState('');
  const [isPromoApplied, setIsPromoApplied] = useState(false);
  const [promoMessage, setPromoMessage] = useState(''); // To display success or error message
  const [discountRate, setDiscountRate] = useState(0); // To track the discount percentage

  const handleApplyPromo = () => {
    const formattedPromoCode = promoCode.toUpperCase();
    if (formattedPromoCode === 'ECU50') {
      setIsPromoApplied(true);
      setDiscountRate(0.5); // 50% discount
      setPromoMessage('Valid Promo Code - 50% off');
    } else if (formattedPromoCode === 'FIRST20') {
      setIsPromoApplied(true);
      setDiscountRate(0.2); // 20% discount
      setPromoMessage('Valid Promo Code - 20% off');
    } else {
      setIsPromoApplied(false);
      setDiscountRate(0); // No discount
      setPromoMessage('Invalid Promo Code');
      setPromoCode(''); // Clear the promo code input after submission
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleApplyPromo(); // Apply promo code when Enter key is pressed
    }
  };

  return (
    <CoursesContainer>
      <h2>Our Courses</h2>

      {/* Promo Code Input */}
      <PromoSection>
        <PromoInput
          type="text"
          placeholder={promoMessage || 'Enter promo code'}
          value={promoCode}
          onChange={(e) => setPromoCode(e.target.value)}
          onKeyPress={handleKeyPress} // Listen for the Enter key press
          isValid={promoMessage.includes('Valid')}
          isInvalid={promoMessage === 'Invalid Promo Code'}
        />
        <PromoButton onClick={handleApplyPromo}>Apply</PromoButton>
      </PromoSection>

      {/* Course Grid */}
      <CourseGrid>
        {coursesData.map((course) => (
          <CourseCard
            key={course.id}
            title={course.title}
            info={course.info}
            originalPrice={`${course.price} EGP`}
            discountedPrice={isPromoApplied ? `${(course.price * (1 - discountRate))} EGP` : null}
          />
        ))}
      </CourseGrid>
    </CoursesContainer>
  );
};


// Styled-components for the Courses section
const CoursesContainer = styled.div`
  padding: 50px;
  background-color: #F5F5F5;
  color: #2C2E33;
  text-align: center;

  h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
  }
`;

const PromoSection = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
`;

const PromoInput = styled.input`
  padding: 10px;
  font-size: 1rem;
  border: 1px solid ${(props) => (props.isValid ? '#28a745' : props.isInvalid ? '#ff4500' : '#ccc')};
  border-radius: 5px;
  margin-right: 10px;
  width: 200px;
  color: ${(props) => (props.isValid ? '#28a745' : props.isInvalid ? '#ff4500' : '#000')};
  transition: box-shadow 0.3s ease, border-color 0.3s ease, color 0.3s ease;

  &::placeholder {
    color: ${(props) => (props.isValid ? '#28a745' : props.isInvalid ? '#ff4500' : '#999')};
  }

  &:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
`;

const PromoButton = styled.button`
  background: #1A73E8; /* Blue background */
  color: #F5F5F5;
  padding: 10px;
  border-radius: 10px;
  width: 100px;
  height: 40px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 1.5s ease;
  margin: 10px;
  position: relative;
  overflow: hidden; /* Prevent overflow when animating gradient */

  /* Remove outer black line */
  border: none; /* Ensures no border */

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(135deg, #FF4500, #1A73E8); /* Orange to Blue gradient */
    opacity: 0;
    transition: opacity 1s ease-in-out;
  }

  &:hover::before {
    opacity: 1; /* Show gradient on hover */
  }

  &:hover {
    transform: scale(1.1); /* Scale button on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Larger shadow on hover */
  }

  z-index: 1; /* Ensures text is always on top */

  @media (max-width: 768px) {
    width: 150px;
    height: 35px;
    padding: 8px;
    font-size: 0.9rem;
  }
`;

const CourseGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 55px;
  justify-items: center;
`;

export default Courses;
