import CPP from './CPP.png';
import Cybersecurity from './Cybersecurity.png';
import Python from './Python.png';
import WebDevelopment from './Web Development.png';

const CourseIcons = {
  'C++': CPP,
  'Intro to Cybersecurity': Cybersecurity,
  'Python': Python,
  'Web Development': WebDevelopment,
};

export default CourseIcons;
